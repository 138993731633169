import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`<Row>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` components are used to arrange content and components on the grid within a page.
To learn more about the grid is built, you can read the docs in the `}<a parentName="p" {...{
          "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/grid"
        }}>{`Carbon`}</a>{` repo.`}</p>
    </PageDescription>
    <h2>{`Row`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` component is a wrapper that adds the `}<inlineCode parentName="p">{`bx--row`}</inlineCode>{` class to a wrapper div. You will want to use this to define rows that you will place `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` components inside of.`}</p>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Grid.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid",
        "path": "components/Grid.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid"
      }}>{`<Row>
  <Column>
    Content or additional <Components />
  </Column>
</Row>
`}</code></pre>
    <Title mdxType="Title">Row props</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Column`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` component is used to define column widths for your content, you can set the rules at different breakpoints with the props.`}</p>
    <h3>{`Example`}</h3>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Title mdxType="Title">No gutter left</Title>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Title mdxType="Title">No gutter</Title>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Title mdxType="Title">Offset</Title>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} offsetLg={4} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grid Example",
            "title": "Grid Example",
            "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
            "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Grid.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid",
        "path": "components/Grid.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid"
      }}>{`<Row>
  <Column colMd={4} colLg={4}>
    ![Grid Example](images/Article_05.png)
  </Column>
  <Column colMd={4} colLg={4}>
    ![Grid Example](images/Article_05.png)
  </Column>
  <Column colMd={4} colLg={4}>
    ![Grid Example](images/Article_05.png)
  </Column>
</Row>
`}</code></pre>
    <Title mdxType="Title">No gutter left</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Grid.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid",
        "path": "components/Grid.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid"
      }}>{`<Row>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    ![Grid Example](images/Article_05.png)
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    ![Grid Example](images/Article_05.png)
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    ![Grid Example](images/Article_05.png)
  </Column>
</Row>
`}</code></pre>
    <Title mdxType="Title">No gutter</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Grid.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid",
        "path": "components/Grid.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid"
      }}>{`<Row>
  <Column colMd={4} colLg={4} noGutterSm>
    ![Grid Example](images/Article_05.png)
  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    ![Grid Example](images/Article_05.png)
  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    ![Grid Example](images/Article_05.png)
  </Column>
</Row>
`}</code></pre>
    <Title mdxType="Title">Offset</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Grid.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid",
        "path": "components/Grid.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Grid"
      }}>{`<Row>
  <Column colMd={4} colLg={4} offsetLg={4}>
    ![Grid Example](images/Article_05.png)
  </Column>
  <Column colMd={4} colLg={4}>
    ![Grid Example](images/Article_05.png)
  </Column>
</Row>
`}</code></pre>
    <Title mdxType="Title">Column props</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colSm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col width at small breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colMd`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col width at medium breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colLg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col width at large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colXl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col width at x-large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colMax`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col width at max breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`offsetSm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col offset at small breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`offsetMd`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col offset at medium breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`offsetLg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col offset at large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`offsetXl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col offset at x-large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`offsetMax`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify the col offset at max breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterSm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter at small breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterMd`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter at medium breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterLg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter at large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterXl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter at x-large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterMax`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter at max breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterSmLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter left at small breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterMdLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter left at medium breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterLgLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter left at large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterXlLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter left at x-large breakpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noGutterMaxLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify no-gutter left at max breakpoint`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      